<template>
  <div>
    <v-app-bar
      clipped-left
      clipped-right
      app
      class="px-0 .d-flex"
      color="background"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <router-link
        style="height: 40px"
        to="/dashboard"
        tag="img"
        :src="require('@/assets/rallylogga_svart.png')"
      />
      <v-spacer></v-spacer>

      <v-img
        class="mx-2"
        :src="require('@/assets/arr_av_utn.png')"
        max-height="110"
        max-width="110"
        contain
      ></v-img>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      clipped
      class="user-drawer"
    >
    <div class="sidebar-container">
      <v-list nav dense >
        <v-list-item-group
          v-model="group"
          active-class="red darken-4--text text--accent-4"
        >
          <v-list-item to="/dashboard" exact class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hem</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/stations" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-van-passenger</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stationer</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/missions" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Uppdrag</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/tricky" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-thought-bubble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kluring</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/haftig" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-flash</v-icon>
            </v-list-item-icon>
            <v-list-item-title>HÄFTiga platser</v-list-item-title>
          </v-list-item>
          <v-list-item to="/dashboard/splek" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-music-clef-treble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Bilunderhållning</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/lunch" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-food</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lunchstation</v-list-item-title>
          </v-list-item>

          <v-divider />

          <v-list-item to="/dashboard/contact" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-card-account-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nö(r)dkontakter</v-list-item-title>
          </v-list-item>

          <v-list-item @click="signout" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logga ut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="easter-egg-img">
        <v-img
          :src="require('@/assets/boten_anna.png')"
          contain
          @click="handleClick"
        ></v-img>
      </div>
    </div>

    </v-navigation-drawer>

    <!-- Popup dialog -->
    <v-dialog v-model="showPopup" max-width="90vw">
      <v-card>
        <v-card-title class="headline">Du har hittat ett easter egg</v-card-title>
        <v-card-text class="subtitle">
          Ta en screenshot och skicka in det i uppdrag 9, som angivet i HÄFTigs HÄFTiga HÄFTe!
        </v-card-text>
        
        <v-img
        :src="require('@/assets/golden-egg.png')"
        max-height="200px"
        contain
      ></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showPopup = false">Stäng</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  data: () => ({
    drawer: false,
    group: null,
    clickCount: 0, // Counter for image clicks
    showPopup: false // Controls the visibility of the popup
  }),
  methods: {
    handleClick() {
      this.clickCount += 1;
      if (this.clickCount === 5) {
        this.showPopup = true; // Show popup when clicked 5 times
        this.clickCount = 0; // Reset the counter
      }
    },
    signout() {
      this.$store.dispatch('signOut');
      this.$router.push({ name: 'Home' }).catch(() => {});
    }
  }
};
</script>

<style>


.listItem {
  text-align: start;
}

.sidebar-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.easter-egg-img {
  padding: 40px;

}

.subtitle{
  text-align: left;
}
</style>